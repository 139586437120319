.news-feed {
  min-width: calc(100% - 652px);
  width: 3500px;
  overflow: hidden;
  display: flex;
  align-items: center;

  span {
    white-space: nowrap;
  }

  label {
    margin-bottom: 0;
  }

  p {
    margin: 10px 0;
    display: flex;
    text-overflow: ellipsis;
    whiteSpace: "nowrap";
  }

  .ticker-item {
    height: 100%;

    img {
      margin-right: 10px;
    }

    white-space: nowrap;

    &::after {
      content: '+++';
      margin: 0 20px;
    }

    a {
      color: #000000;
      font-weight: 700;
    }
  }

  .ticker {
    //min-width: calc(300% - 720px);
    width: 100%;
    margin-left: 10px;
    z-index: 0;
  }

  .ticker__element {
    position: relative;
    display: flex;
    &:first-child{
      background-color: #FFFFFF;
      z-index: 10;
    }
  }
}

ul.railation-twitter-list {
  overflow: hidden;
}

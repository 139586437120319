//
// NRail
//

// Colors
$brand-primary:             rgb(6,222,58); // neon green
$fvm-brand-secondary:    	rgb(233,128,48); // not set
$fvm-brand-tertiary:    	rgb(255,255,255); // not set
$body-color:   		 		rgb(21,41,53); // t-03 dark grey

// NRAil additional colors
$fvm-color-bg-01:    		rgb(255,255,255); // bg-01 white // #FFFFFF
$fvm-color-bg-02:    		rgb(245,247,250); // bg-02 grey // #F5F7FA
$fvm-color-bg-03:    		rgb(240,243,246); // bg-03 grey // #F0F3F6
$fvm-color-bg-04:    		rgb(223,227,230); // bg-04 grey // #DFE3E6
$fvm-color-bg-05:    		rgb(140,155,165); // bg-04 grey // #8C9BA5



// Create grayscale
$gray-lightest:             rgb(160,170,180);

$body-bg:					$fvm-color-bg-02;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width-base: 20px;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
);

// Fonts
$fvm--html--font-size: 14px;
$fvm--html--font-size--up-md: null; // base font size
$font-family-base:       'IBM Plex Sans', sans-serif;
$headings-font-family:   'IBM Plex Sans', sans-serif;
$headings-font-weight:   300;

$fvm--navbar--font-family: null;

$font-size-lg: 16px;
$font-size-sm: 12px;
$font-size-xs: 10px;
$fvm--font-size-md: null;

$font-weight-bold: 600;
$fvm--strong--font-weight: bolder;

$font-size-headline-min: 24px;
$font-size-headline-min-value: 24;

$font-size-text-min: 16px;
$font-size-text-min-value: 16;

$font-size-h1: 32px;


$font-size-h2: 24px;


$font-size-h3: 1.75rem;  // 28px
$font-size-h3-value: 28;
$font-size-h3-min: 20px;
$font-size-h3-min-value: 20;
$font-size-h3-responsive: calc(#{$font-size-h3-min} + (#{$font-size-h3-value} - #{$font-size-h3-min-value}) * (100vw - 320px) / (1920 - 320));
$font-size-h3: $font-size-h3-responsive;

$font-size-h4: 1.3rem;  // 23.4px
$font-size-h4-value: 23.4;
$font-size-h4-responsive: 1.3rem; // default: calc(#{$font-size-headline-min} + (#{$font-size-h4-value} - #{$font-size-headline-min-value}) * (100vw - 320px) / (1920 - 320));

$font-size-h5: 1.15rem; // 20.7px
$font-size-h5-value: 20.7;
$font-size-h5-responsive: 1.15rem; // default: calc(#{$font-size-headline-min} + (#{$font-size-h5-value} - #{$font-size-headline-min-value}) * (100vw - 320px) / (1920 - 320));

$font-size-h6: 1rem;    // 18px
$font-size-h6-value: 18;
$font-size-h6-responsive: 1rem; // default: calc(#{$font-size-headline-min} + (#{$font-size-h6-value} - #{$font-size-headline-min-value}) * (100vw - 320px) / (1920 - 320));

$headings-line-height:   1.25;

$display1-size: 4.75rem; // 76px
$display1-size-value: 76;
$display1-size-min: 24px;
$display1-size-min-value: 24;
$display1-size-responsive: calc(#{$display1-size-min} + (#{$display1-size-value} - #{$display1-size-min-value}) * (100vw - 320px) / (1920 - 320));


$fvm-section-margin-bottom: 50px;

$fvm-loc-control-width: 270px;
$fvm-loc-control-min-width: 270px;


// gform
$fvm-gform--label--color: $body-color;
$fvm-gform--label--active--color: $body-color;
$fvm-gform--gsection--padding-top: map-get(map-get($spacers, 4), x);
$fvm-gform--gsection-title--border-bottom: 1px solid $brand-primary;
$fvm-gform--gsection-title--font-family: $font-family-base;
$fvm-gform--gsection-title--font-size: $font-size-h2;
$fvm-gform--gsection-title--font-style: italic;
$fvm-gform--gsection-title--padding-bottom: map-get(map-get($spacers, 4), x);
$fvm-gform--gsection-title--margin-bottom: 30px;


// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.
$btn-padding-x: 3rem;
$btn-padding-y: 1rem;
$btn-border-radius: 0;
$input-btn-border-width: 2px;

$btn-primary-color: $brand-primary;
$btn-primary-bg: $body-color;
$btn-primary-border: $btn-primary-bg;

$btn-secondary-color: #ffffff;
$btn-secondary-bg: transparent;
$btn-secondary-border: #ffffff;


// Navbar

$navbar-padding-x: 0;
$navbar-padding-y: 0;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .8rem 1.25rem;
$table-sm-cell-padding:         .3rem !default;

//$table-bg:                      #ffffff;
$table-bg:                      $fvm-color-bg-03;

$table-bg-accent:               #ffffff;

//$table-bg-hover:                $fvm-color-bg-03;
$table-bg-hover:                $fvm-color-bg-02;

$table-head-bg:                 $fvm-color-bg-03;

$table-border-color:            $fvm-color-bg-04;

@import "assets/sass/bootstrap-4/bootstrap";
@import "components/LocomotiveListItem/style";
@import "components/NrailBootstrapTable/style";
@import "components/User/style";
@import "components/ReportExportDrawer/style";
@import "components/UserAvatar/style";
@import "components/VerticalLocomotiveList/style";
@import "components/TwitterTicker/style";
@import "../node_modules/react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter";

//html{
//  height: 111vh;
//  overflow: hidden;
//  transform: scale(0.9);
//  transform-origin: 0 0;
//  width: calc(100vw + (11vw ));
//}

// z-index:
// .fvm-loading-spinner: 1000
// .fvm-header: 1050
// .fvm-tooltip: 1080
// .DateRangePicker_picker: 1120
body.fvm-my-nrail {
  font-size: $fvm--html--font-size;

  .page {
    display: flex;
  }

  //overflow: hidden;

  // main content structure
  .fvm-main-content {
    overflow: hidden;
    display: flex;
    $margin-top-offset: 110px;
    margin-top: 80px;
    //margin-left: 210px;
    max-height: calc(100vh - 81px);
    height: calc(100vh - 81px);
    width: 100%;

    .fvm-loc-control {
      width: $fvm-loc-control-width;
      min-width: $fvm-loc-control-min-width;
    }

    .fvm-detailed-page {
      overflow: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 0 1 auto;
      article {
        overflow: hidden;
      }
      //padding-top: $grid-gutter-width-base; // removed by GM
      //padding-bottom: $grid-gutter-width-base; //removed by GM
      //padding-left: $grid-gutter-width-base / 2;
      //padding-right: $grid-gutter-width-base / 2;
      background-color: $fvm-color-bg-02;
      //width: calc(100% - #{$fvm-loc-control-width});
      width: 100%;
    }


    //.fvm-loading-spinner {
    //  position: absolute;
    //  background-color: rgba(255, 255, 255, 0.7);
    //  width: 100%;
    //  height: 100%;
    //  max-width: calc(100vw - 300px);
    //  top: 0;
    //  z-index: 1000;
    //
    //  > div {
    //    right: 50%;
    //    bottom: 50%;
    //    transform: translate(50%, 50%);
    //    position: absolute;
    //  }
    //}

    .fvm-page {
      //min-width: 865px;
      min-width: 885px;
    }

  }

  .fvm-page-header {
    padding-top: 10px;
    padding-right: $grid-gutter-width-base / 2;
    padding-bottom: $grid-gutter-width-base;
    padding-left: $grid-gutter-width-base / 2;
  }

  .fvm-page-title {
    font-size: $font-size-h1;
    font-weight: $headings-font-weight;
    margin-bottom: 0;
  }

  .fvm-sp {
    margin-bottom: $fvm-section-margin-bottom;
  }

  .fvm-section-title {
    font-size: $font-size-h2;
    font-weight: $headings-font-weight;
    margin-bottom: 15px;
  }

  .fvm-locomotive-chip {
    background-color: $fvm-color-bg-04 !important;


    > div {
      background-color: $brand-primary;
      min-height: 28px;
      min-width: 28px;
    }

    svg {
      color: #ffffff;
    }
  }

  .fvm-active {
    color: rgb(28, 221, 67) !important;
  }

  .fvm-inactive {
    color: #66708F !important;
  }

  .fvm-status-active {
    //color: rgb(92, 165, 28);
    color: $brand-primary;
  }

  .fvm-status-inactive {
    color: #CCCFDA;
  }

  .fvm-hide {
    display: none !important;
  }

  .fvm-extend-info-icon {
    display: flex;
    align-items: center;

    .fvm-info-icon {
      display: inline-flex;
      margin-left: 5px;

      svg {
        font-size: 20px;
      }
    }
  }

  .fvm-fill-red {
    color: red;
  }

  i.fvm-fill-black {
    color: #000000;
  }

  .fvm-tooltip {
    z-index: 1500;
    font-style: normal;

  }

  /* forms */

  .fvm-page-profile, .fvm-sp-vertical-locomotive-list, .fvm-page-login, .fvm-form-logbook {
    input:not([type]),
    input[type="date"]:not(.browser-default),
    input[type="datetime-local"]:not(.browser-default),
    input[type="datetime"]:not(.browser-default),
    input[type="email"]:not(.browser-default),
    input[type="number"]:not(.browser-default),
    input[type="password"]:not(.browser-default),
    input[type="search"]:not(.browser-default),
    input[type="tel"]:not(.browser-default),
    input[type="text"]:not(.browser-default),
    input[type="time"]:not(.browser-default),
    input[type="url"]:not(.browser-default),
    textarea {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #152935;
      border-radius: 0;
      outline: none;
      height: 3rem;
      width: 100%;
      font-size: 1rem;
      margin: 0 0 20px;
      padding: 0;
      box-shadow: none;
      box-sizing: content-box;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    }

    input:not([type]):disabled,
    input:not([type])[readonly="readonly"],
    input[type="date"]:not(.browser-default):disabled,
    input[type="date"]:not(.browser-default)[readonly="readonly"],
    input[type="datetime-local"]:not(.browser-default):disabled,
    input[type="datetime-local"]:not(.browser-default)[readonly="readonly"],
    input[type="datetime"]:not(.browser-default):disabled,
    input[type="datetime"]:not(.browser-default)[readonly="readonly"],
    input[type="email"]:not(.browser-default):disabled,
    input[type="email"]:not(.browser-default)[readonly="readonly"],
    input[type="number"]:not(.browser-default):disabled,
    input[type="number"]:not(.browser-default)[readonly="readonly"],
    input[type="password"]:not(.browser-default):disabled,
    input[type="password"]:not(.browser-default)[readonly="readonly"],
    input[type="search"]:not(.browser-default):disabled,
    input[type="search"]:not(.browser-default)[readonly="readonly"],
    input[type="tel"]:not(.browser-default):disabled,
    input[type="tel"]:not(.browser-default)[readonly="readonly"],
    input[type="text"]:not(.browser-default):disabled,
    input[type="text"]:not(.browser-default)[readonly="readonly"],
    input[type="time"]:not(.browser-default):disabled,
    input[type="time"]:not(.browser-default)[readonly="readonly"],
    input[type="url"]:not(.browser-default):disabled,
    input[type="url"]:not(.browser-default)[readonly="readonly"],
    textarea:disabled,
    textarea[readonly="readonly"] {
      color: rgba(0, 0, 0, .26);
      border-bottom: 1px dotted rgba(0, 0, 0, .26);
    }

    input:not([type]):focus:not([readonly]),
    input[type="date"]:not(.browser-default):focus:not([readonly]),
    input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
    input[type="datetime"]:not(.browser-default):focus:not([readonly]),
    input[type="email"]:not(.browser-default):focus:not([readonly]),
    input[type="number"]:not(.browser-default):focus:not([readonly]),
    input[type="password"]:not(.browser-default):focus:not([readonly]),
    input[type="search"]:not(.browser-default):focus:not([readonly]),
    input[type="tel"]:not(.browser-default):focus:not([readonly]),
    input[type="text"]:not(.browser-default):focus:not([readonly]),
    input[type="time"]:not(.browser-default):focus:not([readonly]),
    input[type="url"]:not(.browser-default):focus:not([readonly]),
    textarea:focus:not([readonly]) {
      border-bottom: 1px solid #06de3a;
      box-shadow: 0 1px 0 0 #06de3a;
    }


    label {
      font-size: .8rem;
      color: #152935;
      //display: inline-block;
      margin-bottom: .5rem;
    }
  }


  .fvm-form-invalid-input {
    input {
      border-bottom: 1px solid red !important;
      box-shadow: 0 1px 0 0 red !important;
    }
  }

  // buttons
  .fvm-btn {
    border-radius: 0 !important;
    color: #ffffff !important;
    font-weight: 600 !important;
    text-transform: none !important;
  }

  .fvm-btn-login {
    color: #06de3a !important;
    background-color: #152935 !important;
    padding: 1rem 3rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
  }

  .fvm-btn-login:disabled {
    opacity: 0.65;
  }

  .fvm-save-button {

  }

  .fvm-right-save-button {
    float: right;
  }

  .fvm-cancel-button {
    color: rgba(21, 41, 53, 0.7) !important;
  }

  // Pages/Login
  .fvm-page-login {
    height: 100vh;
    background-image: url('https://my.nrail.de/assets/images/hero-gradient-nrail.jpg');
    background-size: cover;

    .fvm-cp-jumbotron {
      text-align: center;
      width: 45%;

      .fvm-cp-title {
        border-bottom: 9px solid #ffffff;
        color: #ffffff;
        display: inline-block;
        font-size: $display1-size-responsive;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
      }

      .fvm-cp-subtitle {
        color: #ffffff;
        font-size: $display1-size-responsive;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
      }
    }

    .fvm-cp-form {
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
      padding: 30px;
      width: 100%;
      max-width: 420px;
      min-width: 280px;
      margin-left: 10px;
      margin-right: 10px;

      .fvm-cp-image {
        margin-bottom: 20px;
      }
    }

    .fvm-gform {
      width: 100%;
    }

    .fvm-forgot-pw-lang {
      padding: $grid-gutter-width-base;
      color: #ffffff;
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      max-width: 420px;

      a {
        color: #ffffff;
        text-decoration: none;
      }
    }

    .fvm-privacy-policy{
      a {
        color: #ffffff;
        text-decoration: none;
      }
    }
  }

  // Pages/Dashboard
  .fvm-page-dashboard {

    .fvm-map-iframe {
      height: 65vh;
    }

    .fvm-dashboard-table {
      thead th:first-child {
        //width: 50px
        width: 110px
      }

      tbody tr td:first-child {
        //padding: 0.8rem 0.5rem;
      }

      td {
        vertical-align: middle;

        i.fvm-info-icon {
          align-items: center;
          vertical-align: sub;
          margin-left: 5px;

          svg {
            font-size: 16px;
          }
        }
      }
    }


    span.loco-table-item {
      display: inline-flex;

      i {
        margin-right: 10px;
      }
    }
  }

  // Pages/LiveData
  // iPad Pro Auflösung 2732 x 2048 Pixel
  .fvm-page-live-data {

    .fvm-map-iframe {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;

      /*     @media screen and (min-width: 1366px) {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
          } */

      @media screen and (min-width: 1920px) {
        flex: 0 0 75%;
        max-width: 75%;
      }
    }

    .fvm-locomotive-datasheet {
      //padding-top: 60px !important;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;

      /*     @media screen and (min-width: 1366px) {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
          } */

      @media screen and (min-width: 1920px) {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .fvm-title {
        background-color: #ffffff;
        font-weight: 600;
        padding: 0.5rem 1rem;
      }

      .table th, .table td {
        padding: 0.5rem 1rem;
      }
    }

    .fvm-weather {
      padding-top: 0.4rem;
      padding-right: 0.4rem;
      padding-bottom: 0.4rem;
      vertical-align: middle;

      span {
        display: flex;
        align-items: center;
      }

      canvas {
        flex: 0 0 auto;
        margin-right: $grid-gutter-width-base / 2;
      }
    }

  }

  // Pages/Reporting
  .fvm-disabled-elem {
    opacity: 0.4;
  }

  // reporting


  #report-pdf-download-button {
    display: none;
  }

  .fvm-reporting-button-group {
    display: flex;

    > button:not(:last-child) {
      margin-right: 10px;
    }

    > span {
      padding: 15px;
    }

    .fvm-radio-button-group {
      flex-direction: row;
    }
  }

  .fvm-action-bar {
    display: flex;
    justify-content: space-between;
  }

  .fvm-detailed-page {
    //margin-bottom: -20px;
  }

  // Pages/Tracking
  .fvm-page-tracking {


    .fvm-map-iframe {
      height: calc(100vh - 61px - 81px);
      padding-left: 0;
      padding-right: 0;
    }

    .fvm-map-drawer {


      > div {
        //margin-top: 60px;
        top: unset;
        max-height: calc(100vh - 81px) !important;
      }
    }
  }


  // Pages/Notifications

  // Pages/Logbook
  .fvm-page-logbook {

    .fvm-sp-logbook-button-group {
      text-align: right;
      margin-bottom: 10px;

      button {
        margin-right: $grid-gutter-width-base / 2;
        margin-left: $grid-gutter-width-base / 2;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .fvm-table-data {
      overflow: auto;
      //padding-bottom: 20px;
    }

    .fvm-table-data > div > table {
      min-width: 1560px;

      th:nth-child(1) {
        width: 120px;
        min-width: 120px;
      }

      th:nth-child(2) {
        width: 120px;
        min-width: 120px;
      }

      th:nth-child(3) {
        width: 120px;
        min-width: 120px;
      }

      th:nth-child(4) {
        width: auto;
      }

      th:nth-child(5) {
        width: 120px;
        min-width: 120px;
      }

      th:nth-child(6) {
        width: 120px;
        min-width: 120px;
      }

      th:nth-child(7) {
        width: 120px;
        min-width: 120px;
      }

      th:nth-child(8) {
        width: 120px;
        min-width: 120px;
      }

      th:nth-child(9) {
        width: 120px;
        min-width: 120px;
      }

      th:nth-child(10) {
        width: auto;
      }

      th:nth-child(11),
      td:nth-child(11) {
        width: 120px;
        min-width: 120px;
        padding: 10px;
      }

    }

    .fvm-table-th {
      padding: 0;
    }

    .fvm-header-wrapper {
      position: relative;
    }

    .fvm-header-colspan-text {
      background-color: $table-bg;
      padding: $table-cell-padding;
      position: absolute;
      top: 48px;
      left: 0;
      text-align: center;

      &.fvm-col-2 {
        width: calc(100% * 2);
      }

      &.fvm-col-3 {
        width: calc(100% * 3);
      }

    }

    .fvm-works .fvm-header-colspan-text {
      width: calc(100% * 1);
    }

    .fvm-header-number {
      text-align: center;
      border-bottom: 1px solid $table-border-color;
      padding: $table-cell-padding;
    }

    .fvm-header-number {

      &.fvm-first {
        width: 100%;
        border-right: 1px solid $table-border-color;
      }

      &.fvm-second {
        flex: 0 0 90px;
        min-width: 90px;
      }
    }

    .fvm-header-text {
      padding: 0.8rem 0.3rem;
      padding-top: 50px;

      &.fvm-first {
        width: 100%;
        border-right: 1px solid $table-border-color;
      }

      &.fvm-second {
        flex: 0 0 90px;
        min-width: 90px;
      }
    }

    .fvm-logbook-work-inner-table {
      width: 100%;
      height: 100%;

      .fvm-first {
        border-left: 0;
        width: auto;

      }

      .fvm-last {
        border-right: 0;
        width: 90px;
        min-width: 90px;
      }

      tr:first-child td {
        border-top: 0;
      }

      tr:last-child td {
        border-bottom: 0;
      }
    }
  }

  .fvm-form-logbook {

    min-width: 552px; // IE11 fix

    .fvm-title {
      font-weight: $font-weight-bold;
    }

    .fvm-button-group {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      button {
        outline: none;
      }
    }

    .fvm-textarea {
      height: 80px;
    }

  }

  // Pages/Datasheet
  .fvm-page-datasheet {

    .fvm-pdf-downloads {
      margin-bottom: -$grid-gutter-width-base;

      .fvm-cp-item {
        flex: 0 0 20%;
        max-width: 20%;
        min-width: 280px;
        padding-right: 10px;
        padding-left: 10px;
        margin-bottom: $grid-gutter-width-base;

        a {
          color: $body-color;

          @include hover-focus {
            text-decoration: none;
          }
        }
      }

      .fvm-cp-body {
        display: flex;
        align-items: center;
        background-color: #ffffff;
        padding: $grid-gutter-width-base;

        i {
          font-size: 32px;
          display: inline-flex;
          margin-right: 10px;
        }

      }

    }

    .fvm-textarea {
      border-top: 1px solid $fvm-color-bg-04;
      border-right: 1px solid $fvm-color-bg-04;
      border-left: 1px solid $fvm-color-bg-04;
      background-color: #ffffff;
      padding: 10px;
      height: 200px;
      width: calc(100% - 20px);
    }

  }

  // Pages/Profile
  .fvm-page-profile {
    .fvm-button-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .fvm-btn-delete {
      margin-right: $grid-gutter-width-base;
    }

    .user-avatar {
      width: 120px;
      height: 120px;
    }

    .fvm-user-language-container {
      //padding-left: 80px;
      //display: flex;
      //justify-content: space-around;
      //span {
      //  display: inline-flex;
      //  p{
      //    padding-left: 15px;
      //
      //  }
      //}
    }
  }

  // Pages/NotificationSettings
  .fvm-page-notification-settings {
    //.fvm-sp.fvm-sp-settings form.col{
    //  min-height: 80vh;
    //}

    .fvm-settings-group {
      display: flex;
      align-items: center;
      background-color: #ffffff;
      border-bottom: 1px solid $fvm-color-bg-04;
      padding: $grid-gutter-width-base / 2;
      font-weight: $font-weight-bold;
      margin-right: 0;
      margin-left: 0;
    }

    .fvm-cp-specs {
      align-items: center;
    }

    .fvm-cp-specs-disabled {
      color: $fvm-color-bg-04;
    }

    .fvm-label-prefix {
      display: inline-block;
      width: 45px;
    }

    input.fvm-input {
      border-top: 1px solid $fvm-color-bg-04;
      border-right: 1px solid $fvm-color-bg-04;
      border-left: 1px solid $fvm-color-bg-04;
      padding-left: $grid-gutter-width-base / 2;
      background-color: #ffffff;
      margin-bottom: 0;
      width: 75px;
      height: 2rem;
    }

    .fvm-label-suffix {
      display: inline-block;
      margin-left: $grid-gutter-width-base / 2;
    }

    .fvm-btn-group {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 15px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

  }


  .fvm-dialog-show-accept-dialog {
    position: fixed;
    right: calc(50%);
    bottom: 50%;
    transform: translate(50%, 50%);
    z-index: 2000;
    border-radius: 10px;
    width: max-content;
    text-align: center;
    background-color: white;
    padding: 25px;

    .fvm-dialog-title {
      white-space: nowrap;
      font-weight: 700;
    }

    > div {
      max-width: 400px;
      min-width: 200px;
    }

    .fvm-dialog-msg {
      margin-bottom: 40px;
    }

    .fvm-decision-buttons {
      bottom: 0;

      .dialog-accept-button {
        background-color: #0f9d58;
        color: white;
      }

      .dialog-ok-button {
        background-color: dodgerblue;
        color: white;
      }

      .dialog-decline-button {
        background-color: red;
        color: white;
      }

      button {
        margin-left: 25px;
        margin-right: 25px;
        border-radius: 20px;
        padding: 0.75rem 1rem;
      }
    }
  }

  .modal-dialog-settings {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.75) !important;
    z-index: 1500;
  }

  .fvm-list-seperator {
    &::after {
      content: '|';
      margin-top: 4px;
      padding-left: 10px;
      line-height: 40px;
      font-size: 24px;
      color: #DFE3E6;
    }
  }

  // airbnb DateRangePicker calendar
  .DateRangePickerInput {

    //background-color: $fvm-color-bg-02;
    border: 1px solid #CCCFDA;
    //margin-bottom: 5px;

    .DateInput {
      background-color: inherit;
    }


    input {
      font-size: 14px !important;
      margin: 0 !important;
      border: none !important;
      cursor: pointer;
      color: inherit;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    .DateInput__small {
      width: 92px;
    }

    .DateInput_input__focused {
      background-color: rgb(245, 247, 250);
    }

    .DateRangePickerInput_calendarIcon {
      padding: 0 10px 0 0;
      margin: 0;
    }

    .DateRangePickerInput_clearDates_svg__small {
      height: 12px;
      vertical-align: top;
    }

    .DateRangePickerInput_clearDates {
      margin-right: 5px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
    }

    .DateRangePickerInput_arrow {
      display: inline-flex;
      align-items: center;
    }

    .DateInput_fang { //svg arrow icon
      display: none;
    }
  }

  .DateRangePicker_picker {

    z-index: 2200;

    table {
      background-color: transparent;
    }

    .CalendarDay__selected {
      background: rgb(6, 222, 58);
      border-color: rgb(6, 222, 58);
    }

    .CalendarDay__selected_span,
    .CalendarDay__hovered_span {
      background: rgba(6, 222, 58, 0.5);
      border-color: rgba(6, 222, 58, 0.5);
    }

    .CalendarDay__blocked_calendar {
      box-sizing: border-box !important;
      font-size: 14px !important;
      text-align: center !important;
      cursor: default !important;
      background: rgb(255, 255, 255) none repeat scroll 0% 0% !important;
      border: 1px solid rgb(228, 231, 231) !important;
      color: rgb(202, 204, 205) !important;
    }

    .fvm-date-picker-controls {
      display: flex;
      justify-content: space-between;
      margin-right: 20px;
      margin-left: 20px;

      button {
        margin-bottom: 15px;
        border: 0;
        color: rgb(6, 222, 58);
        background-color: transparent;
        font-weight: bold;
        font-size: 14px;

        &:hover {
          cursor: pointer;
        }
      }

    }


  }

  /// draw test

  .fvm-main-content .fvm-detailed-page {
  }


  .fvm-page {
    position: relative;
    width: 100%;
    overflow: auto;
    height: 100%;
    //max-height: calc(100% - 60px);
    .fvm-general-page-content {
      overflow-y: auto;
      height: 100%;
      max-height: 100%;

      section:not(.fvm-sp-map-iframe), header {
        padding-left: $grid-gutter-width-base;
        padding-right: $grid-gutter-width-base;
      }
    }

    .fvm-map-page-toolbar-container + .fvm-general-page-content {
      max-height: calc(100% - 60px);
    }

    .fvm-map-page-toolbar-container {
      top: 0;
      width: 100%;
      z-index: 10;

      .fvm-map-page-toolbar {
        width: 100%;
      }


    }

  }

  .fvm-menu-closed .fvm-page .fvm-map-page-toolbar {
    //max-width: calc(100vw - 321px);
  }

  .fvm-page-notifications, .fvm-page-reporting {
    .fvm-page-header {
      //padding-top: 60px;
    }


  }

  .fvm-map-page-toolbar {
    background-color: #FFFFFF;
    min-height: 60px !important; //
    height: 60px; //
    border-bottom: solid 1px rgb(223, 227, 230);
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 0px !important;
    //padding: 10px;

    .DateRangePickerInput__showClearDates {
      display: inline-flex;
    }

    .fvm-date-picker-listelm {
      margin-bottom: 0;
    }

    ul {
      padding: 0;
      display: inline-flex;
      margin-bottom: 0;

      li:first-child label {
        margin-right: 0;
      }

      li {
        display: flex;
        //margin-right: 10px;

        .fvm-date-picker-title {
          padding-left: 10px;
        }

      }

    }
  }

  //.fvm-page:not(.fvm-page-tracking) .fvm-sp-map-iframe {
  //  > .row {
  //    margin-left: -20px;
  //    margin-right: -20px;
  //  }
  //}

  .page:not(.fvm-railation-page) {
    .fvm-sp-map-iframe .row {
      //margin-left: -20px;
      //margin-right: -20px;
    }
  }

  .fvm-page .fvm-sp-map-iframe {
    .row {
      background-color: #FFFFFF;

      > .col {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .fvm-map-drawer-second_Layer > div {
      input {
        font-style: italic;
        font-size: 16px;
        padding-left: 10px;
        border: 0;
        margin: 0;
      }
    }

    .row {
      .map-detailed-page-control {
        padding: 0;
        overflow: hidden;
      }


      .fvm-map-page-toolbar, .fvm-map-drawer {
        button:focus {
          outline: unset;
        }
      }

      .fvm-map-drawer {
        > div {
          top: unset;
          max-height: calc(65vh);
          position: absolute;
        }

        ul {
          padding-top: 0px;
        }
      }
    }

    // main content structure
    //.fvm-main-content-railation {
    //
    //
    //  display: flex;
    //  $margin-top-offset: 110px;
    //  margin-top: 180px;
    //  max-height: calc(100vh - 180px); //
    //  height: calc(100vh - 180px);
    //
    //  article {
    //    min-width: 100%;
    //
    //    .fvm-map-iframe .fvm-map-page-toolbar {
    //      width: calc(100vw); // nav menu and loc controls subtraction
    //    }
    //  }
    //}

    .fvm-map-filter-menu-item {
      display: inline-flex;
    }

    .filter-search-field {
      width: calc(100% - 48px);
      max-width: 215px;
      display: inline-flex;

    }

    .fvm-filter-item-name-text-group {
      span.fvm-sub-filter-item-category-name {
        color: #8C9BA5;
      }

      .fvm-filter-item-icon svg path {
        fill: #8C9BA5 !important;
      }
    }

    .fvm-filter-item-body > span {
      //font-size: 14px;
      font-weight: 700;
      color: #001044;
      white-space: pre-wrap;
      hyphens: auto;

    }

    .fvm-filter-item-body > p {
      font-size: 14px;
      color: #152935;
      white-space: pre-wrap;
      hyphens: auto;
    }

    .fvm-filter-item-icon {
      font-weight: bold;
      margin-right: 0;
      color: #152935;
    }

    .fvm-main-filter-item-category-name > span {
      font-weight: 600;
    }
  }

  .filter-close-button {
    display: flex;

    &.left {
      flex-direction: row-reverse;
    }

    .filter-close-button-text {
      padding: 10px;
    }
  }

  .fvm-iframe-container {
    height: calc(100%);
  }

  .fvm-railation-page .fvm-main-content {

    margin-top: 0px;
    max-height: 100vh;
    height: 100vh;

    .map-detailed-page-control {
      padding: 0;
      margin-bottom: 0px !important;
      overflow: hidden;
    }

    margin-left: 0 !important;

    .fvm-map-iframe .fvm-map-page-toolbar {
      z-index: 2500;
      min-width: 620px !important;
      width: 620px;
      flex-direction: row-reverse;
    }

    .fvm-page-tracking .fvm-map-iframe {
      height: calc(100vh - 60px);

      .fvm-map-drawer > div {
        max-height: calc(100vh - 60px) !important;
        height: 100vh;
      }
    }

    .fvm-iframe-container {
      padding-top: 0;
      height: 100%;
    }

    article {
      width: 100vw;
    }

    .fvm-loading-spinner {
      max-height: calc(100vh - 184px);
      width: 100%;
      margin: 0;
    }
  }

  .fvm-map-iframe {
    overflow: hidden;

    iframe {
      border: none;
    }
  }

  .fvm-map-drawer {

    > div {
      max-height: calc(100% - 60px);
    }

    .filter-close-button.fvm-filter-controls {
      background-color: #FFFFFF;
    }

    .fvm-filter-list-divider {
      top: 60px;
      position: sticky;
      z-index: 1;
    }

    .fvm-detailed-filter-draw-img {
      min-height: 40%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        object-fit: cover;
        height: 100%;
      }

      i {
        position: absolute;

        svg {
          width: 120px;
          height: 120px;

          path {
            fill: rgb(255, 255, 255) !important;
          }
        }
      }
    }

    .fvm-detailed-filter-item-body {
      padding-top: 15px;
      padding-bottom: 15px;

      .fvm-filter-item-name-text-group {
        display: flex;

        i {
          display: inline-flex;
        }

        .fvm-sub-filter-item-category-name {
          font-size: 16px;
          display: inline-flex;
          padding: 2px 10px;;
        }


      }

      a {
        text-decoration: none;
        color: inherit;
        font-weight: 700;
      }
    }

    .fvm-filter-controls {
      position: sticky;
      background-color: #FFFFFF;
      top: 0;
      z-index: 1;
      height: 60px;
      align-items: center;
      min-height: 60px;
      padding: 0 10px;
      display: flex;

      input {
        width: 100%;

      }

      input:focus {
        border-bottom: unset !important;
        box-shadow: unset !important;
        outline: none !important;
      }

      .filter-search-icon {
        transform: scaleX(-1);
      }
    ;
    }
  }


  // Train Status ICONS

  svg .fvm-total {
    fill: #7a8a96;
  }

  svg .fvm-driving {
    fill: $brand-primary;
  }

  svg .fvm-standing {
    fill: #ff8a00;
  }

  svg .fvm-equipped {
    fill: #5aaafa;
  }

  svg .fvm-off {
    fill: #cccfda;
  }

  .fvm-drawer-controls-dummy {
    height: 44px;
  }


  .fvm-report-upper-controls {
    //display: inline-flex;
    //justify-content: space-between;
    //padding: 20px 0;
    width: 100%;
    //height: 160px;
    height: 140px;
    //background-color: #F8F8F8;
  }


  .fvm-report-loco-list-container {
    background-color: #FFFFFF;
    border: 1px solid #DFE3E6;
    //height: calc(100vh - 160px - 81px);
    //padding: 10px 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .fvm-report-lower-controls {
    display: inline-flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    align-items: center;
    width: 100%;
    max-width: calc(100% - 40px);;
    height: 81px;
    //background-color: #F8F8F8;

    .fvm-report-options .fvm-radio-button-group {
      display: inline;

      label {
        margin-left: 20px;
      }
    }

    .fvm-report-export-buttons button {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .fvm-controls {
    label {
      margin-bottom: 0px;
      justify-content: center;
      //flex-wrap: wrap;

      > span {
        margin: -20px 0;
      }
    }
  }

  .relation-menu-button-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-width: fit-content;

    > span {
      align-items: center;
      display: flex;
    }
  }

  .fvm-reporting-table-caption {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }

  #fvm-graph-elm > div {
    max-height: calc(100vh - 166px - 81px);
  }


  .amcharts-amexport-menu > li.amcharts-amexport-item.amcharts-amexport-item-level-0 {
    //border: 1px solid rgba(28, 221, 67, 0.5);
    //position: fixed;
    min-width: 40px;
    padding: 10px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    background-color: #FFFFFF;

    a {
      background-color: #FFFFFF;
      opacity: 1;
      color: rgb(28, 221, 67);
    }
  }

  .amcharts-amexport-menu > li.amcharts-amexport-item {
    background-color: #FFFFFF;
    border: 1px solid rgba(28, 221, 67, 0.5);
    opacity: 1;
    color: rgb(28, 221, 67);

    :hover {
      opacity: 1;
      background-color: #FFFFFF;
    }
  }

  .fvm-tooltip-img {
    background-color: rgba(0, 0, 0, 0) !important;
    opacity: 1 !important;
    margin-left: 255px !important;
    margin-top: -50px;
    padding: 0;

    img {
      margin: -10px;
    }

    div {
      //background-color: #FFFFFF !important;
      //background-color: transparent;
      //margin: -10px;
    }

    /*&.__react_component_tooltip:after {
      border-left: none;
      left: 0;
      margin-left: 0;
    }*/
    &.__react_component_tooltip:after {
      display: none;
    }
  }

  button:focus {
    outline: 0;
  }


  .reportFilteriInput {
    &:before, &:after {
      display: none;
    }

    input {
      font-size: 14px;
    }
  }


  .fvm-loading-spinner {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 200;

    //margin: 0 -15px;
    justify-content: center;
    display: flex;
    align-items: center;
  }


  .fvm-map-railation-controls {
    .button-group {
      display: inline-flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      max-width: 620px;

      .fvm-controls {
        display: inline-flex;
      }
    }

  }


  .fvm-page-dashboard, .fvm-page-tracking {
    .fvm-map-page-toolbar .fvm-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      ul {
        //min-width: 552px;
      }

      ul.railation-twitter-list {
        justify-content: flex-end;
        max-width: calc(100% - 645px);

        &.full-width-railation {
          max-width: calc(100% - 645px);
        }


        li:last-child {
          margin-right: 0;
          //margin-left: 10px;

          label {
            margin-right: 0;
            //flex-wrap: nowrap;
          }
        }

        .fvm-twitter-list-item {
          //width: calc(100% - 100px);
        }

        &.full-width-logged-in {
          width: 100%;

          .fvm-twitter-list-item {
            width: 100%;
          }
        }

      }

      .raliation-control-list-item-2 {
        label {
          display: inline-flex;
          align-items: center;
        }

      }
    }
  }

  .zero-left-margin label {
    margin-left: 0;
  }

  .fvm-faq-content {
    ol {
      list-style-type: lower-alpha;
    }
  }

  .fvm-page-faq {
    //background-image: url(https://nrail.staging.fuerstvonmartin.de/wp-content/uploads/2019/06/faq.png);
    background-image: url(https://my.nrail.de/assets/images/hero-gradient-nrail.jpg);
    background-size: cover;

    .fvm-faq {
      max-width: 1440px;
      margin: 20px 30px;

      //li img {
      img {
        width: 100%;
        height: auto;
      }

    }
  }


  body .fvm-80-per-zoom {
    .fvm-detailed-page, .fvm-main-navigation, header, aside ul, aside .fvm-view-switcher {
      zoom: 80%;
    }

    .fvm-main-content {
      margin-top: calc(80px * 0.8);
    }

    .infinite-scroll-component {
      min-height: calc(100vh - (305px * 0.8));
    }

    .fvm-main-content .fvm-loc-control {
      min-width: 180px;
    }

    .fvm-main-content {
      max-height: calc(100vh - (81px * 0.8));
      height: calc(100vh - (81px * 0.8));
    }

    .fvm-main-navigation {
      height: 125vh;
    }

    .fvm-controls label {
      //flex-wrap: nowrap;
    }

  }


  /* Table sort indicators */
  .sortable {
    white-space: nowrap;
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .dropup, .dropdown {
    position: relative;
  }

  .dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9
  ;
    content: "";
  }


  .fvm-fuel-slider-container {
    label {
      font-weight: 700;
      color: black;
    }

    .fvm-fuel-slider {
      flex: 0 0 60%;
      $fvm-slider-mark-factor: 6px;

      .MuiSlider-mark {
        width: 1px;
      }

      .MuiSlider-mark {
        &:nth-child(4), &:nth-child(24) {
          height: calc(#{$fvm-slider-mark-factor} * 6) !important;
          margin-top: calc(#{$fvm-slider-mark-factor} * -6 / 2);
        }

        &:nth-child(6), &:nth-child(22) {
          height: calc(#{$fvm-slider-mark-factor} * 5) !important;
          margin-top: calc(#{$fvm-slider-mark-factor} * -5 / 2);
        }

        &:nth-child(8), &:nth-child(20) {
          height: calc(#{$fvm-slider-mark-factor} * 4) !important;
          margin-top: calc(#{$fvm-slider-mark-factor} * -4 / 2);
        }

        &:nth-child(10), &:nth-child(18) {
          height: calc(#{$fvm-slider-mark-factor} * 3) !important;
          margin-top: calc(#{$fvm-slider-mark-factor} * -3 / 2);
        }

        &:nth-child(12), &:nth-child(16) {
          height: calc(#{$fvm-slider-mark-factor} * 2) !important;
          margin-top: calc(#{$fvm-slider-mark-factor} * -2 / 2);
        }

        &:nth-child(14) {
          height: calc(#{$fvm-slider-mark-factor} * 1) !important;
          margin-top: calc(#{$fvm-slider-mark-factor} * -1 / 2);
        }
      }
    }
  }

  .table thead th:focus {
    //background-color: red;
    outline: unset;
  }

  .fvm-display-default-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      position: absolute;

      svg {
        width: 100px;
        height: 100px;

        circle {
          fill: transparent !important;
        }

      }

      &.is-crane svg {
        path {
          fill: white !important;
        }
      }
    }
  }

  .table-footnotes {
    position: absolute;
    bottom: 0;
    font-style: italic;
    font-size: 11px;
    background-color: #FFFFFF;
    width: 100%;
    padding: 5px;

    > span:not(:last-child) {
      &::after {
        padding: 0 5px;
        content: '';
      }
    }
  }

}

.pagination-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.fvm-pagination-lst {
  display: flex;
  padding-left: 0;
  list-style: none;

  li {
    //padding: 8px 16px;
    min-height: 40px;
    min-width: 40px;
    margin: 0 2.5px;
    background-color: #ffffff;
    font-size: 16px;
    border: $brand-primary solid 1px;

    a {
      color: $brand-primary;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:focus, &:hover {
        text-decoration: none;
        outline: none;
      }
    }

    &.fvm-pagination-active {
      a {
        color: #ffffff;
      }

      background-color: $brand-primary;

    }

    &.disabled {
      a {
        color: #FFFFFF;

        &:hover {
          cursor: not-allowed;
        }
      }

      background-color: #66708F;
      border: #66708F solid 1px;


    }

    //border:  $brand-primary solid 1px;
  }
}
.fvm-driving-marker::after{
  display: none;
}


img[src*="/assets/images/locostatus/Train-Moving.png"] + .fvm-driving-marker::after {
  content: '';
  position: absolute;
  display: block !important;
  width: 10px;
  height: 10px;
  transform: translate(-50%, -50%) rotate(45deg);
  top: 3%;
  left: 50%;
  background-color: $brand-primary;
}

img[src*="/assets/images/locostatus/Train-Moving.png"] + .fvm-driving-marker{
  //display: block !important;
  position: absolute;
  z-index: -1;
  width: 40px;
  height: 40px;
}


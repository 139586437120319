.nrail-bootstrap-table {

	&:not(:last-child) {
		margin-bottom: $fvm-section-margin-bottom;
	}
  
  .table {
    margin-bottom: 0;
    //box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.1);
  }
  
	thead {
		font-weight: $font-weight-bold;
	}

	th { 
		border-bottom: 0 !important;
	}

	caption {
		caption-side: top;
		font-size: $font-size-h2;
		font-weight: $headings-font-weight;
		color: $body-color;
		padding-bottom: 15px;
		sup{
			font-size: 11px;
		}
	}

	&.fvm-datasheet-table {

		.table {
			background-color: #ffffff;
		}

		thead {
			display: none;
		}

		tbody td:first-child {
			font-weight: $font-weight-bold;
		}

		tr:nth-of-type(2n+1) {
			background-color: $fvm-color-bg-03;
		}

		tr:hover {
			background-color: $table-bg-hover;
		}

	}
  
  &.fvm-table-notifications-settings {
    margin-top: $fvm-section-margin-bottom / 2;
    margin-bottom: $fvm-section-margin-bottom / 2;
    
    td:hover,
    th:hover {
      cursor: pointer;
    }
  }

}

.fvm-sp-vertical-locomotive-list {

	border-right: 1px solid $fvm-color-bg-04;
	
	.fvm-controls {
		border-bottom: 1px solid $fvm-color-bg-04;
		background-color: #FFFFFF;
		
		.fvm-view-switcher {
			height: 60px;
			padding-left: 20px;
			padding-right: 20px;
			border-bottom: 1px solid $fvm-color-bg-04;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-weight: $font-weight-bold;

			.fvm-pointer {
				display: flex;
			}

			i {
				cursor: pointer;
				margin-left: 5px;
        display: inline-flex;
			}
		}

		ul {
			list-style: none;
			padding: 0px 20px;
			margin-bottom: 5px;
			
			li {
				padding-top: 15px;

				span {
					//font-size: 14px;
					//font-weight: 400;
				}

				&:nth-child(n + 2) {
					border-top: 1px solid $fvm-color-bg-04;
				}
			}
		}
	
		.fvm-list-con-txt {
			padding-bottom: 10px;
			white-space: nowrap;
		}

		.fvm-radio-button {

			display: flex;
			align-items: center;
			cursor: pointer;

			i {
				display: inline-flex;
				width: 18px;
				margin-right: 10px;
			}
      .select-label{
        width: 100%;
      }

		}
		
		.fvm-loc-filter {
			background-color: $fvm-color-bg-02 !important;
			font-size: 14px !important;
			padding-left: 10px !important;
			padding-right: 10px !important;
			width: calc(100% - 20px) !important;
			border: none !important;
		}
		
		.fvm-multi-select,
		.fvm-date-picker-title {
			font-weight: $font-weight-bold;
			white-space: nowrap;
		}

	}
  
	.fvm-locomotive-list {
	
		ul {
			list-style: none;
			padding: 20px 20px 0 20px;
			margin-bottom: 0;
		}

		li {
			margin-bottom: 30px;
		}

	}
  

.dropdown { // tbd

  &:hover {
    cursor: pointer;
  }

  &.dropdown--active {
    .dropdown__content {
      display: block;
    }
  }

  .dropdown__content {
    display: none;
    position: absolute;
    z-index: 10;
    top: 38px;
    left: 0;
    width: calc(100% + 66px);
    background-color: #ffffff;

    ul{
      margin-left: 20px;
    }

    .fvm-dropdown-group {
      padding-left: 0;
      margin-bottom: 0;
      background-color: #fff;
      box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.1);
    }

    .fvm-dropdown-group-item {
      display: flex;
      border-bottom: 1px solid $fvm-color-bg-04;

      a {
        //display: flex;
        //align-items: center;
        padding: 0.75rem 1.25rem;
        width: 100%;
        color: inherit;
        text-decoration: none;

        &:hover {
          color: #1d1e1f;
          text-decoration: none;
          background-color: #f7f7f9;
        }

        i {
          margin-right: 10px;
          margin-left: 10px;
        }
      }
    }

  }

}


  .hide-locomotive-card{
    display: none;
  }

}

.fvm-cp-locomotive {
	background-color: #ffffff;
	position: relative;
	cursor: pointer;
	border-bottom: 2px solid #ffffff;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	&:hover,
	&.active {
		box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.1);
		border-bottom: 2px solid $brand-primary;
	}

	&.not-connected {
		box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.1);
		border-bottom: 2px solid red;
	}

	.fvm-cp-body-wrapper { // IE11 fix for vertical justify-content
		display: flex;
		flex-direction: column;
	}
	
	.fvm-cp-body {
		padding: 15px;
		min-height: 110px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.fvm-cp-title {
		font-weight: 600;
		margin-bottom: 0;
	}
	
	.fvm-status-icons {
		display: flex;
		justify-content: space-between;
    align-items: center;
		padding-top: 15px;
		
		div span{
			font-size: 10px;
		}
	}

	.fvm-cp-icon {
		display: flex;
		flex-direction: column;
		position: absolute;
		right: 10px;
		top: 5px;
		font-size: 1.4rem;
		
		i {
			background-color: #ffffff;
			border-radius: 50%;
			//padding: 1px;
			margin-top: 5px;
			line-height: 0;
		}
		
		.fvm-loco-card-focus-inactive {
			color: rgb(140, 155, 165);
		}

	}


	&.fvm-loc-layer::after {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.35);
		position: absolute;
		top: 0;
		left: 0;
	}

}

.fvm-locomotive-list {
	&.fvm-list-view {
		.fvm-cp-title {
			padding-right: 23px;
		}
	}



	.locomotive-card{
		position: relative;
		.fvm-loco-disabled {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,.3);
		}
	}
}

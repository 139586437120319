.fvm-header {

  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1250;
  background-color: #ffffff;
  border-bottom: 1px solid $fvm-color-bg-04;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding-left: $grid-gutter-width-base / 2;
  padding-right: $grid-gutter-width-base / 2;


  .fvm-logo {
    height: 60px;
  }


  .fvm-user {
    display: flex;
    height: 60px;

    .fvm-user-avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 20px;
    }

    .fvm-user-name-datetime {
      min-width: 200px;

      .fvm-user-name {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0.25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          line-height: 0px;
          font-size: 30px;
        }
      }

      .fvm-user-datetime {
        font-size: 14px;
        display: flex;
        align-items: center;

        svg {
          font-size: 18px;
        }

        span {
          display: inline-block;
          padding-left: 5px;
          vertical-align: middle;
        }

        .fvm-user-time {
          min-width: 55px;
        }

        .fvm-user-date {
          min-width: 85px;
        }
      }

    }

  }


  .fvm-my-locomotives {

    font-size: $font-size-sm;
    margin-left: auto;
    padding-right: 20px;
    display: flex;
    align-items: center;

    .fvm-cp-icon {
      display: inline-flex;
      align-items: center;
      font-style: normal;
      margin-left: 5px;

      &:hover {
        cursor: pointer;
      }
    }

    .MuiSvgIcon-root-fvm {
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 24px;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      -moz-user-select: none;
    }

    .fvm-user-train-counter {
      margin-left: 4px;
    }

    .fvm-cp-icon {
      margin-right: 10px;

    }

  }

  .dropdown {

    &:hover {
      cursor: pointer;
    }

    &.dropdown--active {
      .dropdown__content {
        display: block;
        z-index: 300;
      }
    }

    .dropdown__content {
      display: none;
      position: absolute;
      top: 69px;
      left: 0;
      width: calc(100% + 8px);
      background-color: #ffffff;

      .fvm-dropdown-group {
        padding-left: 0;
        margin-bottom: 0;
        background-color: #fff;
        box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.1);
        list-style: none;
      }

      .fvm-dropdown-group-item {
        border-bottom: 1px solid $fvm-color-bg-04;

        .fvm-lang-switcher, a {
          display: flex;
          align-items: center;
          padding: 0.75rem 1.25rem;
          width: 100%;
          color: $body-color;
          text-decoration: none;
          font-weight: $font-weight-bold;

          &:hover {
            color: #1d1e1f;
            text-decoration: none;
            background-color: #f7f7f9;
          }

          i {
            display: inline-flex;
            margin-right: 10px;
          }
        }

      }

    }

  }

  @media screen and (max-width: 480px) {
    .fvm-my-locomotives {
      display: none;
    }

    .fvm-user {
      display: flex;
      height: 40px;

      .fvm-user-avatar {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 20px;
      }

      .fvm-user-name-datetime {
        min-width: 140px;

        .fvm-user-name > * {
          font-size: 14px !important;
        }

        .fvm-user-name {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0.25rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          i {
            line-height: 0px;
            font-size: 30px;
          }
        }

        .fvm-user-datetime {
          font-size: 12px;
          display: flex;
          align-items: center;

          svg {
            font-size: 18px;
          }

          span {
            display: inline-block;
            padding-left: 5px;
            vertical-align: middle;
          }

          .fvm-user-time {
            min-width: 45px;
          }

          .fvm-user-date {
            min-width: 60px;
          }
        }

      }

    }
    .dropdown {

      .dropdown__content {
        top: 60px;
        left: -42px;
        width: calc(100% + 50px);
      }
    }
  }

}

.fvm-lang-switcher {
  display: flex;

  i {
    margin-right: 3px;
  }

  span {
    padding: 2px 0;
  }

  span:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  span:not(:last-child)::after {
    content: '|';
    padding: 2px 5px;
  }

  .fvm-inactive {
    color: #66708F;
  }
}
